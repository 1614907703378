<template>
  <div class="contract-detail">
    <v-breadcrumbs :items="items" class="bg-silver">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <div class="bg-silver">
      <div
        v-for="(list, i) in lists"
        :key="i"
        class="
          t-container
          mx-auto
          bg-smoke
          shadow-smoke
          text-title--szm
          color-gray-dark
        "
      >
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <p>{{ $t("contractDetail.membershipNumber") }}</p>
              <p>{{ list.number }}</p>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <p>{{ $t("contractDetail.contract") }}</p>
              <p>{{ list.share }}</p>
            </div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <p>{{ $t("contractDetail.pointsRemaining") }}</p>
              <p>{{ list.point }} {{$t("contract.year.point")}}</p>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <p>{{ $t("contractDetail.expirationDatePoint") }}</p>
              <p>{{ list.day }}</p>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <p>{{ $t("contractDetail.pointsLeft") }}</p>
              <p>{{ list.pointsRemaining2 }}{{$t("contract.year.point")}}</p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="px-5">
        <v-btn
          elevation="0"
          @click="submitButtonDetail"
          class="btn-icon bg--orange mx-auto text-heading"
          block
          rounded
          x-large
        >
          <span class="text-center w-100">{{ $t("buttons.plusPoints") }}</span>
          <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
        <v-btn
          elevation="0"
          class="btn-icon bg--blue mx-auto mt-4 text-heading"
          block
          rounded
          x-large
        >
          <span class="text-center w-100">{{ $t("buttons.usePoint") }}</span>
          <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contract-detail',
  components: {},
  data () {
    return {
      items: [
        {
          text: this.$t('title.main'),
          disabled: false,
          href: '/home'
        },
        {
          text: this.$t('newsPage.contractList'),
          disabled: true,
          href: ''
        }
      ],
      lists: [
        {
          number: '0002-00091',
          share: 'シェアリング ポイント',
          point: '325',
          day: '2020-12-15',
          pointsRemaining2: '6000'
        }
      ]
    }
  },
  methods: {
    submitButtonDetail () {
      this.$router.push('/home/contract-request')
    }
  }
}
</script>

<style scoped lang="scss">
@use '../../assets/styles/modules/variables-scss' as v;

.contract-detail > div {
  padding: 27px 21px 68px;
  margin-top: 1px;
}

.shadow-smoke {
  max-width: 500px;
  border-radius: 5px !important;
  box-shadow: -2px -2px 5px var(--color__white) !important;
}
.color-gray-dark {
  color: var(--color__gray-dark) !important;
}
.theme--light.v-divider {
  margin: 25px auto 27px;
}
.theme--light.v-btn {
  color: var(--color__white) !important;
}
.v-btn {
  margin-top: 44px;
}

@media (max-width: v.$breakpoint__Extra-small) {
  .contract-detail {
    .text-title--szm {
      font-size: var(--font-size__normal) !important;
    }
    .text-heading {
      font-size: var(--font-size__title) !important;
    }
  }
}
</style>
